<template>
    <div>
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>交易流水</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24" style="text-align:left;">
                            <div class="searchblock">
                                <el-button type="primary" size="small" @click="SelectMerchant"
                                           style="margin-right:10px;" v-if="ismerchant != 1">选择商户</el-button>
                                <el-select v-model="tradetype" filterable style="margin-right:10px;width:150px"
                                           clearable placeholder="请选择交易类型" size="small">
                                    <el-option label="全部类型" value="0"></el-option>
                                    <el-option label="租金" value="1"></el-option>
                                    <el-option label="押金" value="2"></el-option>
                                    <el-option label="滞纳金" value="7"></el-option>
                                    <el-option label="违约金" value="3"></el-option>
                                    <el-option label="买断金" value="4"></el-option>
                                    <el-option label="赔偿金" value="5"></el-option>
                                    <el-option label="退款" value="6"></el-option>
                                </el-select>
                                <el-date-picker v-model="range" type="daterange" unlink-panels range-separator="至"
                                                start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts"
                                                style="margin-right:10px;" size="small"></el-date-picker>
                                <el-input v-model="keyword" placeholder="请输入订单号/交易流水号" prefix-icon="el-icon-search"
                                          style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;"
                                           size="small">搜索</el-button>
                                <el-button type="primary" size="small">
                                    <download-excel class="btn btn-default" :fetch="fetchData" :fields="json_fields"
                                                    :before-generate="startDownload" :before-finish="finishDownload" type="csv"
                                                    name="交易流水.csv">
                                        <span style="padding: 11px 20px; margin: 11px -20px">导出</span>
                                    </download-excel>
                                </el-button>
                                <el-button type="warning" @click="offline" style="margin-left:10px;" size="small"
                                           v-if="ismerchant != 1">线下入账</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                          element-loading-spinner="el-icon-loading" element-loading-text="加载中..." size="medium">
                    <el-table-column label="所属商户" min-width="150" prop="Merchant">
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{ scope.row.Merchant }}</span>
                            <span v-else>深圳市闲牛科技有限公司</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单号" prop="OrderUID"></el-table-column>
                    <el-table-column label="客户名" width="120px" prop="NickName"></el-table-column>
                    <el-table-column label="交易流水号" prop="BillNo" min-width="150"></el-table-column>
                    <el-table-column label="交易时间" :formatter="Getdate" prop="AddDTime"
                                     min-width="100"></el-table-column>
                    <el-table-column label="交易金额" width="100px">
                        <template #default="scope">
                            <span v-if="scope.row.Source == 1" style="color:rgb(112, 182, 3)">+
                                {{ scope.row.BillMoney }}</span>
                            <span v-if="scope.row.Source == 2" style="color:rgb(255, 20, 20)">-
                                {{ scope.row.BillMoney }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="交易类型" width="120px">
                        <template #default="scope">
                            <span v-if="scope.row.TradeType == 1">租金</span>
                            <span v-if="scope.row.TradeType == 2">押金</span>
                            <span v-if="scope.row.TradeType == 7">滞纳金</span>
                            <span v-if="scope.row.TradeType == 3">违约金</span>
                            <span v-if="scope.row.TradeType == 4">买断金</span>
                            <span v-if="scope.row.TradeType == 5">赔偿金</span>
                            <span v-if="scope.row.TradeType == 6">退款</span>
                            <span v-if="scope.row.TradeType == 8">强制买断金</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="备注" prop="Remark"></el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                               :current-page="curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                               layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                               style="text-align:center"></el-pagination>
            </div>
        </el-card>
        <el-dialog v-model="addbill" title="线下入账" width="60%" @close="CloseBill">
            <div class="search">
                <el-input v-model="orderuid" placeholder="请输入订单号" prefix-icon="el-icon-search" size="small"
                          style="width:300px;margin-right:15px"></el-input>
                <el-button type="primary" size="small" @click="GetOrderBill">查询</el-button>
            </div>
            <el-form :model="incomeinfo" label-width="120px" label-position="center" style="width:100%;margin-top:15px;"
                     ref="incomeinfo" :rules="rules" :inline="true">
                <el-form-item label="输入订单号" required prop="orderuid">
                    <el-input v-model="incomeinfo.orderuid" size="small" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="选择入账类型" required prop="tradetype">
                    <el-select v-model="incomeinfo.tradetype" style="width:100px;" placeholder="请选择">
                        <el-option label="租金" value="1"></el-option>
                        <el-option label="滞纳金" value="2"></el-option>
                        <el-option label="违约金" value="3"></el-option>
                        <el-option label="买断金" value="4"></el-option>
                        <el-option label="赔偿金" value="5"></el-option>
                        <el-option label="强制买断金" value="6"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="输入金额" required prop="offincome">
                    <el-input-number :controls="false" size="small" v-model="incomeinfo.offincome"
                                     :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="交易流水号" required prop="offno">
                    <el-input size="small" v-model="incomeinfo.offno"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input size="small" v-model="incomeinfo.remark"></el-input>
                </el-form-item>
            </el-form>
            <el-button size="small" type="primary" @click="SaveOfflineIncome">确定</el-button>
            <el-divider></el-divider>
            <el-table :data="billdata" border style="width: 100%; margin: 1em 0" size="medium"
                      v-if="billdata.length > 0">
                <el-table-column label="期数" width="50px" prop="RentOrder" align="center"></el-table-column>
                <el-table-column label="应还租日" prop="PayDate" :formatter="Getdate" align="center"></el-table-column>
                <el-table-column label="应还租金" width="100px" prop="RentMoney" align="center"></el-table-column>
                <el-table-column label="实还租金" width="100px" prop="PayMoney" align="center"></el-table-column>
                <el-table-column label="未还租金" width="100px" align="center">
                    <template #default="scope">
                        <span>{{ scope.row.RentMoney * 1 - scope.row.PayMoney * 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="本期是否结清" width="150px" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.Status == 0">未结清</span>
                        <span v-else>已结清</span>
                    </template>
                </el-table-column>
                <el-table-column label="本期结清时间" align="center" prop="PayDTime">
                    <template #default="scope">
                        <span v-if="!scope.row.PayDTime">-</span>
                        <span v-else>{{ scope.row.PayDTime }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="summarydata" border style="width: 100%; margin: 1em 0" size="medium"
                      v-if="billdata.length > 0">
                <el-table-column label="总应还滞纳金" align="center" prop="overdue"></el-table-column>
                <el-table-column label="减免滞纳金" align="center" prop="mitigate"></el-table-column>
                <el-table-column label="已还滞纳金" align="center" prop="payoverdue"></el-table-column>
                <el-table-column label="剩余应还滞纳金" align="center" prop="needoverdue"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog v-model="merchant" title="选择商户" center>
            <div class="merchant-block">
                <el-transfer v-model="selmerchant" filterable filter-placeholder="请输入商户名称" :data='merchantlist'
                             :titles='transfertitle'>
                </el-transfer>
                <div class="bottom">
                    <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                    <el-button size="small" @click="Back">取消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<style>
.el-card {
    padding: 0 !important;
}

.el-card__body {
    padding: 10px;
}

.el-card__header {
    padding: 10px !important;
}

.merchant-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
import JsonExcel from "vue-json-excel3";
import { ElMessage } from "element-plus";
export default {
    components: {
        downloadExcel: JsonExcel,
    },
    data() {
        return {
            startDownload() {
                ElMessage({
                    type: "warning",
                    message: "开始下载",
                    center: true,
                });
            },
            finishDownload() {
                ElMessage({
                    type: "success",
                    message: "下载完成",
                    center: true,
                });
            },
            json_fields: {
                所属商户: "Merchant",
                订单号: "OrderUID",
                客户名: "NickName",
                交易流水号: "BillNo",
                交易时间: "AddDTime",
                交易金额: 'BillMoney',
                交易类型: {
                    field: "TradeType",
                    callback: (value) => {
                        if (value == 1) {
                            return `租金`;
                        }
                        if (value == 2) {
                            return `押金`;
                        }
                        if (value == 3) {
                            return `违约金`;
                        }
                        if (value == 4) {
                            return `买断金`;
                        }
                        if (value == 5) {
                            return `赔偿金`;
                        }
                        if (value == 6) {
                            return `退款`;
                        }
                        if (value == 7) {
                            return `滞纳金`;
                        }
                        if (value == 8) {
                            return `强制买断金`;
                        }
                    },
                },
                备注: "Remark"
            },
            selmerchant: [],
            transfertitle: ['商户列表', '已选商户'],
            merchantlist: [],
            merchant: false,
            curpage: 1,
            pagesize: 10,
            totalcount: '',
            incomeinfo: {
                offincome: '',
                orderuid: '',
                offno: '',
                tradetype: '',
                remark: ''
            },
            rules: {
                orderuid: [{
                    required: true,
                    message: '请填写订单编号',
                    trigger: 'blur'
                }],
                offincome: [{
                    required: true,
                    message: '请填写金额',
                    trigger: 'blur'
                }],
                tradetype: [{
                    required: true,
                    message: '请选择入账类型',
                    trigger: 'blur'
                }],
                offno: [{
                    required: true,
                    message: '请填写交易流水号',
                    trigger: 'blur'
                }]
            },
            billdata: [],
            tabledata: [],
            summarydata: [{
                overdue: 0,
                mitigate: 0,
                needoverdue: 0,
                payoverdue: 0
            }],
            addbill: false,
            range: '',
            tradetype: '0',
            keyword: '',
            shortcuts: [
                {
                    text: '最近一周',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: '最近一个月',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: '最近三个月',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
            ],
            orderuid: '',
        }
    },
    methods: {
        async fetchData() {
            const response = await this.axios.get(constant.exportbilldata, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    tradetype: this.tradetype,
                    keyword: this.keyword,
                    range: this.range,
                    mid: this.mid,
                    selmerchant: JSON.stringify(this.selmerchant),
                }
            })
            console.log(response.data)
            if (response.data.length == 0) {
                ElMessage({
                    type: "warning",
                    message: "暂无数据可下载",
                    center: true,
                });
                return false;
            } else {
                return response.data;
            }
        },
        ConfirmMerchant() {
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back() {
            this.merchant = false;
        },
        SelectMerchant() {
            this.merchant = true;
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        offline() {
            this.addbill = true;
        },
        Getdate(row, column) {
            var cur_date = row[column.property];
            console.log(cur_date);
            return formatDate(cur_date);
            function formatDate(date) {
                var datetime = new Date(date * 1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2);
                var second = ("0" + datetime.getSeconds()).slice(-2);
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        GetOrderBill() {
            this.billdata = [];
            this.summarydata = [{
                overdue: 0,
                mitigate: 0,
                needoverdue: 0,
                payoverdue: 0
            }];
            this.axios.get(constant.get_order_bill2, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    orderid: this.orderuid,
                    type: 2
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data.billdata) {
                    this.billdata = response.data.billdata;
                }
                if (response.data.otherinfo) {
                    this.summarydata[0].overdue = ((response.data.otherinfo.Overdue * 1).toFixed(2)) * 1;
                    this.summarydata[0].mitigate = ((response.data.otherinfo.Mitigate * 1).toFixed(2)) * 1;
                    this.summarydata[0].needoverdue = ((response.data.otherinfo.Overdue * 1 - response.data.otherinfo.Mitigate * 1 - response.data.otherinfo.PayOverdue * 1).toFixed(2)) * 1;
                    this.summarydata[0].payoverdue = ((response.data.otherinfo.PayOverdue * 1).toFixed(2)) * 1;
                } else {
                    this.$message.error("查询不到该订单");
                }
            })
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        SaveOfflineIncome() {
            this.$refs['incomeinfo'].validate((valid) => {
                if (valid) {
                    this.axios.post(constant.save_offincome, this.incomeinfo).then((response) => {
                        console.log(response.data);
                        if (response.data == "OK") {
                            this.addbill = false
                            this.$message.success("操作成功");
                        } else {
                            this.$message.error(response.data);
                        }
                    })
                } else
                    return false;
            });
        },
        init() {
            this.axios.get(constant.get_trade, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    tradetype: this.tradetype,
                    keyword: this.keyword,
                    range: this.range,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                    selmerchant: JSON.stringify(this.selmerchant),
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        CloseBill() {
            this.orderuid = "";
            this.incomeinfo.tradetype = "";
            this.incomeinfo.offincome = 0,
                this.incomeinfo.orderuid = '',
                this.incomeinfo.offno = '',
                this.incomeinfo.remark = '',
                this.billdata = [];
            this.summarydata = [{
                overdue: 0,
                mitigate: 0,
                needoverdue: 0,
                payoverdue: 0
            }];
        },
        withdraw(id) {
            this.$confirm("确定要退款给客户么？", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.get(constant.refundmoney, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("退款成功");
                        this.$router.go(0);
                    }
                });
            }).catch(() => {
                return false;
            })
        }
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_trade, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                curpage: this.curpage,
                pagesize: this.pagesize,
                ismerchant: this.ismerchant,
                mid: this.mid,
            }
        }).then((response) => {
            console.log(response.data);
            this.tabledata = response.data.list;
            this.curpage = response.data.curpage * 1;
            this.totalcount = response.data.totalcount * 1;
        });
        this.axios.get(constant.allmerchant, {
            headers: {
                "Content-Type": 'application/json'
            }
        }).then((response) => {
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for (let i = 0; i < list.length; i++) {
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
    }
}
</script>
